import React, { useEffect, useState } from 'react';
import './SessionRate.scss';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import { submitSessionRate } from '../../../utilities/apiService';
import { Session } from '../../../types';

type Props = {
    messagesEndRef: any;
    session: Session;
};

const SessionRate = ({messagesEndRef, session}: Props) => {
    const [sessionLiked, setSessionLiked] = useState<boolean | null>(null);
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [comment, setComment] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const onRateIconSubmit = () => {
        try {
            submitSessionRate(session.hash_id, {liked: sessionLiked, comment: comment}).then(() => {
                setSubmitted(true);
                setShowCommentInput(false);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleRateIconClick = (liked: boolean) => {
        setSessionLiked(liked);
        setShowCommentInput(true);
    };

    useEffect(() => {
        if (session) {
            if (session.liked != undefined) {
                setSessionLiked(session.liked);
            }

            if (session.comment != undefined) {
                setComment(session.comment);
            }
        }
    }, [session]);

    useEffect(() => {
        if (showCommentInput) {
            messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }, [showCommentInput]);

    return (
        <div className="session-rate">
            {!submitted &&
            <div className="d-flex">
                <Icon type="origami_small"/>
                <div>
                    <Text left bold label="eth.session_rate.title" message="Rate your session"/>
                    <Text lightBlack80 label="eth.session_rate.text"
                          message="Your feedback matters and we would love to hear your thoughts on today's leadership session."/>
                </div>
                <div className="session-rate__icons d-flex">
                    <div className={`cursor-pointer rate-icon dislike ${!sessionLiked && sessionLiked !== null ? 'active' : ''}`}>
                        <Icon type="thumb_down" onClick={() => handleRateIconClick(false)}/>
                    </div>
                    <div className={`cursor-pointer rate-icon like ${sessionLiked ? 'active' : ''}`}>
                        <Icon type="thumb_up" onClick={() => handleRateIconClick(true)}/>
                    </div>
                </div>
            </div>
            }
            {!submitted && showCommentInput &&
                <div className="session-rate__comment">
                    <Text xs bold label="eth.session_rate.comment.title" message="Add comment (optional)"/>
                    <input className="session-rate__comment--input w-100" onChange={(e) => setComment(e.target.value)}/>
                    <div className="d-flex session-rate__comment--buttons">
                        <Button style="secondary" text="Cancel" size="small" className="ms-auto" onClick={() => {setShowCommentInput(false); setSessionLiked(null)}}/>
                        <Button
                            text="Submit"
                            icon={<Icon type="check" className="check-icon"/>}
                            size="small"
                            onClick={onRateIconSubmit}
                        />
                    </div>
                </div>
            }
            {submitted &&
                <div className="session-rate__success d-flex">
                    <Icon type="info"/>
                    <Text left white label="eth.session_rate.success.text" message="Thank you for submitting your feedback."/>
                </div>
            }
        </div>
    );
};

export default SessionRate;
