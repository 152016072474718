import React from 'react';
import './PrivacyPolicyModal.scss';
import Button from '../Button';
import Icon from '../Icon';
import { updatePrivacyPolicy } from '../../utilities/apiService';
import { useMsal } from '@azure/msal-react';
import Checkbox from '../Checkbox';

interface PrivacyPolicyModalProps {
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TITLE = 'Welcome to your Leadership Companion';
const TEXT = 'The Leadership Companion tool supports you in your role and decision-making process. To provide you with personalized insights and support, we process your data securely and responsibly, in accordance with ETH Zürich’s data protection standards and applicable privacy laws. We value your privacy and are committed to protecting it through secure storage, ethical use, and full transparency. You can read the full details in our [Privacy Notice / Terms of Use].';

const PrivacyPolicyModal = ({setIsModalOpen}: PrivacyPolicyModalProps ) => {
    const [checked, setChecked] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const { instance } = useMsal();

    const onChange = () => {
        setChecked(!checked);
    }

    const onAccept = async () => {
        if (!checked) {
            setIsError(true);
            return
        }
        setIsError(false);
        const res = await updatePrivacyPolicy();
        if (res.status === 200) {
            localStorage.setItem('privacy-policy-accepted', 'true');
            setIsModalOpen(false);
        }
    }

    return (
        <div className="modal-overlay z-3">
            <div className="privacy-policy-modal-wrapper">
                <div className="privacy-policy-modal-text">
                    <div className="privacy-policy-modal-title">{TITLE}</div>
                    <div className="privacy-policy-modal-text">{TEXT}</div>
                </div>
                <div className="privacy-policy-modal-check-wrapper">
                    <Checkbox checked={checked} onChange={onChange} label="I have read and I accept the Privacy Notice / Terms of Use." />
                </div>
                {isError &&
                    <div className="privacy-policy-modal-error">
                        <div>Please accept Privacy Policy before you continue.</div>
                    </div>
                }
                <div className="privacy-policy-modal-button-wrapper">
                    <Button
                        // eslint-disable-next-line react/style-prop-object
                        style="secondary"
                        btnClass="privacy-text"
                        btnIcon="privacy-icon"
                        icon={<Icon type="cross_small" className="button-icon" />}
                        text="Exit Leadership Companion"
                        onClick={() => instance.logout()}
                        inverted
                    />
                    <Button
                        btnClass="privacy-text"
                        btnIcon="privacy-icon"
                        icon={<Icon type="arrow_forward_small" className="button-icon" />}
                        text="Accept Privacy Policy"
                        onClick={onAccept}
                        inverted
                    />
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicyModal;
