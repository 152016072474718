import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Session.scss';
import SessionHeader from './SessionHeader';
import { getSession, getSessions } from '../../utilities/apiService';
import { Session as SessionType } from '../../types';
import { useParams } from 'react-router-dom';
import Message from './Message';
import TextInput from '../../components/TextInput';
import useWebSocket from '../../hooks/useWebSocket';
import { history, useAppSelector } from '../../store';
import { sessionActions, settingsActions } from '../../actions';
import { useDispatch } from 'react-redux';
import ProgressBar from '../../components/ProgressBar';
import { MilestoneId } from '../../types';
import MilestoneBlock from '../../components/MilestoneBlock';
import { useMsal } from '@azure/msal-react';
import { HelpIcon } from '../../assets/img';
import { AccountDropdown, HelpDropdown } from './Dropdowns';
import FeedbackModal from './SessionModal';
import SessionSummary from './SessionSummary';
import SessionRate from './SessionRate';
import Loader from '../../components/Loader';
import { ProfileButtons } from '../../components/ProfileButtons';

const Session = () => {
    const dispatch = useDispatch();
    const messagesEndRef = useRef(null); // ToDo: replace with scroll the container
    const messagesEndRefMob = useRef(null);
    const messagesEndRefSessionRate = useRef(null);
    const messagesEndRefSessionRateMob = useRef(null);
    const milestoneRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());
    const milestoneRefsMob = useRef<Map<string, HTMLDivElement | null>>(new Map());
    const firstMessageRef = useRef<HTMLDivElement | null>(null);
    const firstMessageRefMob = useRef<HTMLDivElement | null>(null);
    const prevHashIdRef = useRef(null);

    const [initialMessageSent, setInitialMessageSent] = useState(false);
    const [currentSession, setCurrentSession] = useState<SessionType>(null);
    const [isMilestoneSetting, setIsMilestoneSetting] = useState(false);
    const [milestoneType, setMilestoneType] = useState<MilestoneId>('problem');
    const [milestoneText, setMilestoneText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isSpeechMode, setIsSpeechMode] = useState(false);

    const activeSession = useAppSelector(state => state.session.activeSession);
    const auth = useAppSelector(state => state.auth);
    let initialMessage = useAppSelector(state => state.session.message);
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    const [sessionCompleted, setSessionCompleted] = useState(false);
    const isLeftMenuActive = useAppSelector(state => state.setting.isLeftMenuOpen);
    const isRightSidebarOpen = useAppSelector(state => state.setting.isRightSidebarOpen);

    const isMobile = window.innerWidth <= 450;
    const wsUrl = isLocalhost
        ? `ws://localhost:8000/ws/` // WebSocket for local development
        : `wss://${window.location.hostname}/ws/`; // Secure WebSocket for production

    const params = useParams();
    const {
        messages,
        sendMessage,
        deleteTurn,
        objective,
        problem,
        sources,
        links,
        resetChat,
        chatId,
        switchSession,
        isAuthenticated,
        requestSessionSummary,
        sessionSummary,
        sessionTitle,
        isProcessing,
        goal,
        solution,
        action,
        milestone,
        updateMilestone,
        cancelMilestone,
    } = useWebSocket(wsUrl, auth.accessToken);

    const handleSession = useCallback(
        (hash: string) => {
            getSession(hash)
                .then(session => {
                    if (session) {
                        sessionActions.setActiveSession(session)(dispatch);
                        setCurrentSession(session);
                    } else {
                        console.warn('No session found, redirecting to dashboard');
                        history.push('/dashboard');
                    }
                })
                .catch(error => {
                    console.error('Error fetching session:', error.message);
                    history.push('/dashboard');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [dispatch, setCurrentSession, sources]
    );

    const scrollToMilestone = (type: MilestoneId) => {
        if (type === 'discovery') {
            firstMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            firstMessageRefMob.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            const milestoneElement = milestoneRefs.current.get(type);
            const milestoneElementMob = milestoneRefsMob.current.get(type);
            if (milestoneElementMob) {
                milestoneElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            if (milestoneElement) {
                milestoneElementMob.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    };

    useEffect(() => {
        if (params.hash) {
            handleSession(params.hash);
        } else if (window.location.pathname === '/session') {
            setIsLoading(false);
        }
    }, [params.hash]);

    useEffect(() => {
        sessionActions.setSources(sources)(dispatch);
    }, [sources]);

    useEffect(() => {
        sessionActions.setLinks(links)(dispatch);
    }, [links]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        messagesEndRefSessionRate.current?.scrollIntoView({ behavior: 'smooth' });
        messagesEndRefMob.current?.scrollIntoView({ behavior: 'smooth' });
        messagesEndRefSessionRateMob.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    useEffect(() => {
        if (sessionSummary?.summaryText !== null) {
            sessionActions.setSessionSummary(sessionSummary)(dispatch);
        }
    }, [sessionSummary]);

    useEffect(() => {
        if (!initialMessageSent && initialMessage && isAuthenticated) {
            localStorage.setItem('activeSessionId', '');
            sessionActions.setActiveSession(null)(dispatch);
            setCurrentSession(null);
            setInitialMessageSent(true);
            sendMessage(initialMessage, false, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialMessageSent, initialMessage, dispatch, isAuthenticated]);

    useEffect(() => {
        if (!initialMessage && localStorage.getItem('activeSessionId') === '') {
            setIsSpeechMode(false);
            setCurrentSession(null);
            resetChat();
        }
    }, [localStorage.getItem('activeSessionId'), initialMessage]);

    useEffect(() => {
        if (!currentSession && chatId && messages.length == 2 && messages[1].finished) {
            try {
                getSessions()
                    .then(updatedSessions => {
                        sessionActions.loadSessions(updatedSessions)(dispatch);
                        if (updatedSessions && updatedSessions.length > 0) {
                            const active = updatedSessions.find(s => s.hash_id === chatId);

                            if (active) {
                                sessionActions.setActiveSession(active)(dispatch);
                                localStorage.setItem('activeSessionId', active.hash_id);
                                setCurrentSession(active);
                                history.push(`/session/${active.hash_id}`);
                            }
                        }
                    })
                    .catch(() => {
                        console.log('Error occurred when fetching sessions');
                    });
            } catch (error) {
                console.error('Error updating active session:', error);
            }
        }
    }, [chatId, messages]);

    useEffect(() => {
        if (milestone && milestone.milestoneType && milestone.isSetting) {
            setMilestoneType(milestone.milestoneType);
            setMilestoneText(milestone.milestoneText);
            setIsMilestoneSetting(true);
        } else if (
            milestone &&
            !milestone.isSetting &&
            (activeSession?.hash_id || window.location.pathname === '/session')
        ) {
            setIsMilestoneSetting(false);
            handleSession(activeSession?.hash_id);
        } else if (milestone && milestone.error && milestone.error.length > 0) {
            console.error('Error updating milestone', milestone.error);
        }
    }, [activeSession?.hash_id, handleSession, milestone]);

    useEffect(() => {
        if ((currentSession && currentSession.objective === 'Wrap Up') || objective === 'Wrap Up') {
            // Check if session summary is already present
            if (sessionSummary?.summaryText !== null) {
                setSessionCompleted(true);
            } else {
                requestSessionSummary().then(completed => {
                    if (completed) {
                        setSessionCompleted(true);
                    } else {
                        setSessionCompleted(false);
                    }
                });
            }
        }
    }, [currentSession, objective]);

    useEffect(() => {
        if (activeSession?.hash_id && activeSession.hash_id !== prevHashIdRef.current) {
            setIsSpeechMode(false);
            prevHashIdRef.current = activeSession.hash_id;
        }
    }, [activeSession?.hash_id]);

    return (
        <>
            {!isLoading && <ProfileButtons />}
            <div className="session-wrapper position-relative">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <SessionHeader sessionHash={params.hash} title={currentSession?.session_title} />
                        <div
                            className={`session-bar-container h-100 ${isLeftMenuActive ? 'left-menu-active' : ''} ${
                                isRightSidebarOpen ? 'right-menu-active' : ''
                            }`}
                        >
                            <ProgressBar activeSession={currentSession} onMilestoneClick={scrollToMilestone} />
                            <div className="session-container container position-relative">
                                {messages &&
                                    messages.map((message, idx) => {
                                        if (
                                            message.type === 'problem' ||
                                            message.type === 'goal' ||
                                            message.type === 'solution' ||
                                            message.type === 'action'
                                        ) {
                                            return (
                                                <MilestoneBlock
                                                    key={idx}
                                                    message={message}
                                                    completed={sessionCompleted}
                                                    ref={el => {
                                                        if (message.type) {
                                                            milestoneRefs.current.set(message.type, el);
                                                        }
                                                    }}
                                                />
                                            );
                                        }
                                        return (
                                            <Message
                                                key={idx} message={message}
                                                ref={idx === 0 ? firstMessageRef : null}
                                                isLastMessage={idx === (messages.length - 2)}
                                                sendMessage={sendMessage} deleteTurn={deleteTurn}
                                            />
                                        );
                                    })}
                                {sessionCompleted && (
                                    <>
                                        <SessionSummary
                                            summaryText={sessionSummary?.summaryText}
                                            summaryTitle={sessionSummary?.summaryTitle}
                                        />
                                        <SessionRate messagesEndRef={messagesEndRef} session={currentSession} />
                                    </>
                                )}
                                <div ref={messagesEndRef} />
                            </div>
                            {!isMobile &&
                                <div
                                    className={`container session-input-container z-1 ${
                                        isLeftMenuActive ? 'left-menu-active' : ''
                                    } ${isRightSidebarOpen ? 'right-menu-active' : ''}`}
                                >
                                    <TextInput
                                        isDisabled={!isAuthenticated || sessionCompleted || objective === 'None'}
                                        isProcessing={isProcessing}
                                        onSubmit={val => {
                                            sendMessage(val, false);
                                        }}
                                        isSetMilestone={isMilestoneSetting}
                                        setIsSetMilestone={setIsMilestoneSetting}
                                        milestoneType={milestoneType}
                                        milestoneText={milestoneText}
                                        updateMilestone={updateMilestone}
                                        messages={messages}
                                        sendMessage={sendMessage}
                                        className="border-for-chat"
                                        cancelMilestone={cancelMilestone}
                                        isSpeechMode={isSpeechMode}
                                        setIsSpeechMode={setIsSpeechMode}
                                    />
                                </div>
                            }
                        </div>
                        {isMobile &&
                            <div className="session-bar-container-mob">
                                <ProgressBar activeSession={currentSession} onMilestoneClick={scrollToMilestone} />
                                <div className="session-container-mob">
                                    {messages &&
                                        messages.map((message, idx) => {
                                            if (
                                                message.type === 'problem' ||
                                                message.type === 'goal' ||
                                                message.type === 'solution' ||
                                                message.type === 'action'
                                            ) {
                                                return (
                                                    <MilestoneBlock
                                                        key={idx}
                                                        message={message}
                                                        ref={el => {
                                                            if (message.type) {
                                                                milestoneRefs.current.set(message.type, el);
                                                            }
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <Message
                                                    key={idx} message={message}
                                                    ref={idx === 0 ? firstMessageRef : null}
                                                    isLastMessage={idx === (messages.length - 2)}
                                                    sendMessage={sendMessage} deleteTurn={deleteTurn}
                                                />
                                            );
                                        })}
                                    {sessionCompleted && (
                                        <>
                                            <SessionSummary
                                                summaryText={sessionSummary?.summaryText}
                                                summaryTitle={currentSession?.session_title}
                                            />
                                            <SessionRate messagesEndRef={messagesEndRefMob} session={currentSession} />
                                        </>
                                    )}
                                    <div ref={messagesEndRefMob} />
                                </div>
                                <div
                                    className={`container session-input-container z-1 ${
                                        isLeftMenuActive ? 'left-menu-active' : ''
                                    } ${isRightSidebarOpen ? 'right-menu-active' : ''}`}
                                >
                                    <TextInput
                                        isDisabled={!isAuthenticated || sessionCompleted || objective === 'None'}
                                        isProcessing={isProcessing}
                                        onSubmit={val => {
                                            sendMessage(val, false);
                                        }}
                                        isSetMilestone={isMilestoneSetting}
                                        setIsSetMilestone={setIsMilestoneSetting}
                                        milestoneType={milestoneType}
                                        milestoneText={milestoneText}
                                        updateMilestone={updateMilestone}
                                        messages={messages}
                                        sendMessage={sendMessage}
                                        className="border-for-chat"
                                        cancelMilestone={cancelMilestone}
                                        isSpeechMode={isSpeechMode}
                                        setIsSpeechMode={setIsSpeechMode}
                                    />
                                </div>
                            </div>
                        }
                    </>
                )}
            </div>
        </>
    );
};

export default Session;
